.data-row-title {
  width: 140px;
  font-size: 13px;
  font-weight: bold;
  background-color: var(--primary);
  padding: 4px;
  text-align: left;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-content: center;

  @media screen and (max-width: 1500px) {
      width: 60px;
      font-size: 8px;
  }

  @media print {
    padding: 0px;
    font-size: 10px;
    width: 120px;
  }
}

.data-row-field {
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--primary);
  padding: 0 5px 0 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media print {
    height: auto;
    min-height: -webkit-fill-available;
  }
}

.container {
  display: grid;
  grid-template-columns: min-content auto;
  filter: drop-shadow(0px 0px 4px #00000020);
  overflow: hidden;
}