.chart-wrapper {
    padding: 20px;
    background-color: var(--white);
    .recharts-legend-wrapper {
        left: 50px !important;
        .recharts-legend-item-text {
            font-size: 11px;
            margin-left: 0px;
            margin-right: 5cd / px;
            line-height:  10px;
            color: var(--gray);
        }
    }

    .recharts-cartesian-axis-tick {
        text {
            font-size: 11px;
        }
    }
}

.chart-title {
    font-weight: normal;
    font-size: 24px;
    color: var(--gray);
    margin-block-start: 0;
}
