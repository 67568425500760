.help-button {
    position: fixed;
    bottom: 10px;
    left: 10px;
    background-color: var(--primary);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    svg {
        width: 32px !important;
        height: 32px !important;
        color: white;
    }
}

.help-video-modal {
    display: flex;
    flex-direction: column;

    .button {
        display: block;
        width: min-content;
        margin: auto;
        margin-top: 16px;
    }
    
    video {
        max-width: 600px;
    }
}
