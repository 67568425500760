@import '../../scss/colours';

.portal-card {
    position: relative;
    text-decoration: none;
    text-align: center;
    overflow: hidden;
    display: flex;

    > div {
        background: var(--white);

        border-radius: 8px;

        padding: 50px 35px 50px 35px;

        > * {
            margin: 0;
        }

        transition: all .25s;

        width: 100%;
    }

    > div > .icon > span {
        color: var(--secondary);
    }

    > div > .icon > span > :first-child {
        color: var(--primary);
    }

    > div > .title {
        color: var(--secondary);
        font-size: 1.5em;
        font-weight: 500;
    }

    > div > .description {
        color: var(--secondary);
    }

    &.not-available {
        > div {
            background-color: var(--light-gray);
            cursor: default;
        }
    }

    &.available {
        > div:hover {
            background: var(--primary);
            color: var(--white);

            > *, > * > * > * {
                color: white !important;
            }
        }
    }

    .float-house {
        position: absolute;
        width: 100px;
        height: 100px;
        right: -30px;
        top: -30px;
        color: var(--primary);
        opacity: 0.2;
    }
}
