@import '../../scss/colours';

.kpi-card {
    background: var(--white);
    padding: 2em 1.6em;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    align-items: flex-start;

    .float-house {
        position: absolute;
        width: 100px;
        height: 100px;
        right: -30px;
        top: -30px;
        color: var(--primary);
        opacity: 0.2;
    }
}
