@import '../../scss/colours';

.card {
    border-top: 5px solid var(--primary);

    border-radius: 5px;

    background: var(--white);

    padding: 2em 4em 2em 4em;
    @media screen and (max-width: 700px) {
        padding: 0.5em 1em 0.5em 1em;
    }

    &.slim {
        padding: 1em 2em 1em 2em;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .grid {
        &:not(:last-of-type) {
            button {
                margin-bottom: 40px;
            }
        }
    }
}
