.AAC {
    display: grid; 
    grid-template-columns: 3fr 1fr; 
    gap: 25px;
}

.quality-assurance {
    background-color: white;
    border-radius: 5px;
    padding: 1em;
    border: 1px solid var(--primary);
    display: grid;
    gap: 15px;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr;

    div.reject {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .button.reject {
        padding: 10px;
        height: max-content;
        align-self: end;
        cursor: pointer;
    }

    > div {
        display: grid;
    }
}

.dcv-reject {
    > p {
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    button {
        width: 15em;
    }
}

@media print {
    .AAC {
        grid-template-columns: auto;
    }
    .AAC .recharts-wrapper {
        height: 700px !important;
    }
    .AAC .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}