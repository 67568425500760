@import '../../scss/colours';

.large-link-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-radius: 5px;

    text-align: center;

    padding: 2em;

    background: var(--white);
    color: var(--primary);

    height: 180px;

    > svg {
        font-size: 3em;
    }

    > p {
        text-decoration: none;
        font-size: 1.2em;
        color: var(--secondary);
        transition: all .25s;
    }

    transition: all .25s;

    &:hover {
        background: var(--primary);
        color: var(--white);

        > p {
            color: var(--white);
        }
    }
}
