.filters {
  filter: drop-shadow(0px 0px 4px #00000020);
  display: flex;
  flex-direction: column;

  .filter-title {
      display: grid;
      grid-template-columns: min-content auto min-content;
      background-color: var(--primary);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      padding: 5px 10px;
      color: white;
      gap: 15px;

      svg {
          height: 100%;
          display: flex;
          align-items: center;
      }
      .title {

      }
      .clear {
          white-space: nowrap;
          font-weight: bold;
          font-size: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
      }
  }

  @media print {
    display: none;
  }
}