.CotValidation {
    .data-row {
        display: grid;
        grid-template-columns: min-content auto;
        filter: drop-shadow(0px 0px 4px #00000020);

        &.with-buttons {
            grid-template-columns: min-content auto min-content;

            button {
                padding: 5px;
            }
        }

        .data-row-title {
            width: 140px;
            font-size: 13px;
            font-weight: bold;
            background-color: var(--primary);
            padding: 4px;
            text-align: center;
            color: white;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            display: flex;
            justify-content: center;
            flex-direction: column;

            @media screen and (max-width: 1500px) {
                width: 60px;
                font-size: 8px;
            }
        }

        .data-row-field {
            border: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            padding-left: 15px;
            min-width: 0;
        }

        .data-row-field-np {
            border: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            min-width: 0;
        }

        .message {
            position: absolute;
            right: 10px;
            top: 5px;
            color: #ff000069;
        }

        .error-message {
            position: absolute;
            right: 2%;
            color: rgba(255,0,0,.41);
            font-weight: 700;
        }

        &.file-date {
            max-width: 400px;

            .data-row-field {
                background-color: white;
                padding-right: 10px;
                padding-left: 0px;
                select {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background-color: white;
                    padding-left: 10px;
                }
            }
        }
    }

    min-width: 100%;
    display: grid;
    gap: 15px;

    textarea, input, select {
        &:focus {
            outline-color: #00000033;
            box-shadow: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: #ececec;
        }
    }

    .primary-grid {
        min-width: 100%;
        max-width: 70vw;
        display: grid;
        gap: 15px;
        grid-template-columns: 3fr 1fr;

        .left-grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;

            .left-grid-bottom {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
            }

            .name-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
            }
        }

        .right-grid {
            .search-button {
                border: none;
                background-color: var(--primary);
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }

            .buy-button {
                border: none;
                background-color: #445468;
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    .secondary-grid {
        display: grid;
        min-height: 400px;

        > * {
            height: 150px;
        }

        .table {
            width: 100%;
            thead {
                font-size: 10px;
                tr {
                    th {
                        border:1px solid #e8e8e8;
                        // padding: 1em;
                        &:first-child {
                            border-left: none;
                            width: 60px;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
            tbody {
                font-size: 10px;
                tr {
                    height: 30px;
                    overflow: hidden;
                    td {
                        height: 30px;
                        overflow: hidden;
                        &:first-child {
                            // text-align: left;
                        }
                        &:not(:first-child) {
                            word-wrap: normal;
                            word-break: break-all;
                        }
                    }
                }
            }
        }

        .map {
            height: 100%;
            background-color: grey;
            &.hidden {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }
    }

    .grid-overview {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 15px;
        .left, .right {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .left {
            grid-template-rows: min-content min-content auto;
        }
    }

    .table-format {
        display: grid;
        grid-template-columns: auto auto;
        gap: 15px;

        .group-list {
            .data-row-field {
                padding-left: 5px;
                display: grid;
                grid-template-columns: auto auto auto auto auto auto;
                background-color: var(--primary);
            }

            button {
                border: none;
                color: white;
                font-weight: bolder;
                background-color: transparent;
                height: 100%;

                &.active {
                    color: black;
                }
            }
        }

        .display-type {
            width: 100%;
            grid-template-columns: auto min-content;
            :only-child {
                grid-column-start: 1;
                grid-column-end: 2;
                gap: 0
            }
            select {
                border-radius: 5px;
                background-color: white;
                border: none;
            }
            p {
                cursor: pointer;
                margin: 0;
                color: var(--primary);
                padding-left: 15px;
            }
        }
    }

    .filters {
        filter: drop-shadow(0px 0px 4px #00000020);
        display: grid;

        .filter-title {
            display: grid;
            grid-template-columns: min-content auto min-content;
            background-color: var(--primary);
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            padding: 5px 10px;
            color: white;
            gap: 15px;

            svg {
                height: 100%;
                display: flex;
                align-items: center;
            }
            .clear {
                white-space: nowrap;
                font-weight: bold;
                font-size: 12px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        .filter-content {
            background-color: white;

            padding: 15px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            .filter-row {
                display: grid;
                gap: 5px;
                .filter-row-title {
                    font-weight: bold;
                }
                .filter-row-data {
                    display: grid;

                    @for $i from 0 through 12 {
                        &.items-#{$i} {
                            grid-template-columns: repeat($i, 1fr);
                            button {
                                padding-top: 5px;
                                padding-bottom: 5px;
                                border-radius: 0;
                                background-color: white;
                                border: 1px solid var(--primary);
                                border-right: none;
                                &:last-of-type {
                                    border: 1px solid var(--primary);
                                }
                                &:first-child {
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                }
    
                                &:last-child {
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
    
                                &.active {
                                    background-color: var(--primary);
                                    color: white;
                                }
                            }
    
                            &.items-#{$i} {
                                grid-template-columns: repeat($i, 1fr);
                            }
                        }
                    }
                    
                }
            }
        }
    }

    .CotValidation-Summary {
        margin-right: auto;
        table {
            padding-top: 10px;
            margin-bottom: 100px;
        }
        tr {
            color:white;
        }
        th div {
            font-size: 12px;
        }
        .right {
            grid-gap: 15px;
            gap: 15px;
            display: flex;
            flex-direction: column;
            .filters {
                filter: drop-shadow(0 0 4px rgba(0,0,0,.1254901961));
                display: grid;
            }
        }
    }
    .CotValidation-Summary-Tables {
        white-space: nowrap;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        table {
            border: 1px solid;
            padding: 10px;
            float: right;
            border-collapse: separate;
            border-spacing: 20px 0;
            background-color: #ececec;
            margin-bottom: 50px;
        }
        tr {
            display: flex;
            justify-content: flex-end;
        }
        th {
            display: flex;
            margin-right: 10px;
            border: 1px solid black;
            padding: 0px;
            text-align: left;
            min-height: 47px;
            box-sizing: border-box;
            color: white;
            background-color: var(--primary);
            div:first-of-type {
                background-color: var(--primary);
                padding: 10px 15px 10px 10px;
                align-self: center;
                width: 140px;
            }
            div:nth-of-type(2) {
                width: 70px;
                background-color: white;
                color: black;
                padding: 15px 10px 15px 10px;
                align-self: center;
            }
        }
        @media (max-width: 1930px)  and (min-width: 1501px) {
            th div:first-of-type {
                width: 120px;
                font-size: 11px;
            }
            th div:nth-of-type(2) {
                width: 60px;
                font-size: 11px;
            }
        }
        @media (max-width: 1500px) {
            th div:first-of-type {
                width: 110px;
                font-size: 11px;
            }
            th div:nth-of-type(2) {
                width: 50px;
                font-size: 10px;
            }
        }
    }
    .DOB-table {
        text-align: center;
        thead {
            tr {
                background-color: var(--primary);
            }
        }
        th {
            padding: 10px 10px 10px 10px;
        }
        td:first-of-type {
            background-color: var(--primary);
            font-weight: 700;
            color: white;
        }
        td {
            font-weight: 500;
            padding: 10px;
            color: black;
        }
        tbody {
            tr:nth-of-type(even) {
                background: #e9ebf5;
            }
            
            tr:nth-of-type(odd) {
                background: #f0f8ff;
            }
        }
        @media (max-width: 1930px)  and (min-width: 1501px) {
            th {
                width: 120px;
                font-size: 11px;
            }
            td {
                width: 110px;
                font-size: 10px;
            }
        }
        @media (max-width: 1500px) {
            th {
                width: 120px;
                font-size: 11px;
            }
            td {
                width: 110px;
                font-size: 10px;
            }
        }
    }
    .DOB-table th {
        width: 140px;
        font-size: 12px;
    }
    
    .DOB-table td {
        width: 140px;
        font-size: 12px;
    }

    .react-datepicker__navigation {
        width: 32px;
    }

    .react-datepicker__input-container {
        display: contents;
    }

    .react-datepicker-wrapper {
        width: 100%;
        display: inherit;
    }

    .react-datepicker__view-calendar-icon input {
        padding: 1px 2px 1px 25px;
    }

    .react-datepicker__input-container .react-datepicker__calendar-icon {
        padding: 0.33rem;
    }
}

.no-radius {
    border-top-right-radius: 0 !important;
}

.cha-table-or-graph {
    background-color: white;
    filter: drop-shadow(0px 0px 4px #00000020);
    border-radius: 5px;
    padding: 20px;
    // max-height: 600px;
    &.using-graph {
        min-height: 500px;
        max-height: 700px;

        padding-bottom: 75px;

        @media print {
            max-height: 590px;
        }
    }
}


.bulk-buy-button {
    background-color: #445468;
    color: white;
    font-weight: 700;
}

.bold {
    font-weight: 700;
}

.cha-data-row-title {
    font-size: 13px;
    font-weight: bold;
    background-color: var(--primary);
    padding: 4px;
    text-align: center;
    color: white;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    &.disabled {
        background-color: var(--gray);
        height: 29px;
    }
}

@media print {
    .CotValidation .right:has(.filters) {
        display: none;
    }
    
    .CotValidation .primary-grid:has(.right .filters) {
        grid-template-columns: unset;
    }
}