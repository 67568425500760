.card.timeout-challenge {
    p {
        text-align: center;
        .countdown {
            display: inline-block;
            min-width: 30px;
            max-width: 30px;
        }
    }

    button {
        display: block;
        margin: auto;
        margin-top: 48px;
    }
}
