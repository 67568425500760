.container {
    display: grid;

    justify-content: center;

    @media print {
        justify-content: left;
        padding: 5px;
    }

    > div {
        display: grid;
        width: 72vw;
        @media screen and (max-width: 700px) {
            width: 95vw;
        }
        @media print {
            width: 95vw;
        }
        // {{display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '2em', width: '85vw'}}
    }
}
