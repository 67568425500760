.login {
    text-align: center;
}

.login-wrapper {
    margin: auto;
    max-width: 500px;
    min-width: 500px;

    @media screen and (max-width: 740px) {
        max-width: auto;
        min-width: auto;
    }

    .error {
        padding: 5px;
        border-radius: 5px;
        background-color: red;
        color: white;
        text-align: center;
    }

    .forgot-password {
        margin-top: 25px;
        font-size: 12px;
        color: var(--gray);
        margin-left:20px;
        position: relative;
        display: block;
        text-decoration: none;

        cursor: pointer;

        svg {
            margin-right: 10px;
        }

        &:hover {
            color: var(--black);
            transition: all .3s;
            .fa-lock-alt {
                opacity: 0;
            }
            .fa-unlock-alt {
                opacity: 1;
            }
            margin-top: 23px;
            padding-bottom: 2px;
        }

        .fa-lock-alt {
            opacity: 1;
            position: absolute;
            left: -15px;
            top: 2px;
        }

        .fa-unlock-alt {
            position: absolute;
            opacity: 0;
            left: -15px;
            top: 2px;
        }
    }
}
