.bulk-allocate {
    p, small, h3 {
        display: block;
        width: 100%;
        text-align: center;
    }

    .grid-2 {
        gap: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 64px;
    }

    .input {
        display: grid;
        margin-top: 32px;
        grid-template-columns: min-content auto;
        gap: 16px;

        label {
            white-space: nowrap;
        }

        input {
            border: none;
            border-bottom: 3px solid var(--primary);
            font-size: 18px;
            padding-left: 8px;
            transition: all .3s;

            &:focus {
                border-bottom: 0px;
            }
        }
    }

    .cancel {
        background-color: var(--red);
        color: white;
        font-weight: 700;
    }

    .confirm {
        background-color: var(--cobalt);
        color: white;
        font-size: 700;
    }
}
