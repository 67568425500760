.HMA {
    display: grid; 
    grid-template-columns: 3fr 1fr; 
    gap: 25px;
}

@media print {
    .HMA {
        grid-template-columns: auto;
    }
    .HMA .recharts-wrapper {
        height: 700px !important;
    }
    .HMA .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}