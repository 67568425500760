.vacantCOTAlerts {
    .data-row {
        display: grid;
        grid-template-columns: min-content auto;
        filter: drop-shadow(0px 0px 4px #00000020);

        &.with-buttons {
            grid-template-columns: min-content auto min-content;

            button {
                padding: 5px;
            }
        }

        .data-row-title {
            width: 140px;
            font-size: 13px;
            font-weight: bold;
            background-color: var(--primary);
            padding: 4px;
            text-align: center;
            color: white;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            @media screen and (max-width: 1500px) {
                width: 60px;
                font-size: 8px;
            }
        }

        .data-row-field {
            border: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            padding-left: 15px;
            min-width: 0;
        }

        .data-row-field-np {
            border: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            min-width: 0;
        }

        .message {
            position: absolute;
            right: 10px;
            top: 5px;
            color: #ff000069;
        }

        .error-message {
            position: absolute;
            right: 35%;
            color: rgba(255,0,0,.41);
            font-weight: 700;
        }

        &.file-date {
            max-width: 400px;

            .data-row-field {
                background-color: white;
                padding-right: 10px;
                padding-left: 0px;
                select {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background-color: white;
                    padding-left: 10px;
                }
            }
        }
    }

    min-width: 100%;
    display: grid;
    gap: 15px;

    textarea, input, select {
        &:focus {
            outline-color: #00000033;
            box-shadow: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: #ececec;
        }
    }

    .primary-grid {
        min-width: 100%;
        max-width: 70vw;
        display: grid;
        gap: 15px;
        grid-template-columns: 3fr 1fr;

        .left-grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;

            .left-grid-bottom {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
            }

            .name-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
            }

            .search-button {
                border: none;
                background-color: var(--primary);
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }
        }

        .right-grid {
            .search-button {
                border: none;
                background-color: var(--primary);
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }

            .buy-button {
                border: none;
                background-color: #445468;
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    .secondary-grid {
        display: grid;
        min-height: 300px;

        > * {
            height: 150px;
        }

        .table {
            width: 100%;
            thead {
                font-size: 10px;
                tr {
                    th {
                        border: 1px solid #e8e8e8;
                        padding: 2px 10px 2px 10px;
                        &:first-child {
                            border-left: none;
                            width: 130px;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
            tbody {
                font-size: 10px;
                tr {
                    height: 10px;
                    overflow: hidden;
                    td {
                        height: 10px;
                        line-height: 10px;
                        overflow: hidden;
                        &:first-child {
                            text-align: left;
                        }
                        &:not(:first-child) {
                            word-wrap: normal;
                            word-break: break-all;
                            line-height: 10px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        .map {
            height: 100%;
            background-color: grey;
            &.hidden {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }
    }

    .grid-overview {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 15px;
        .left, .right {
            display: grid;
            gap: 15px;
        }

        @media print {
            .right {
                display: none;
            }
            grid-template-columns: 1fr;
        }

        .left {
            grid-template-rows: min-content min-content auto;
        }
    }

    .table-format {
        display: grid;
        grid-template-columns: auto auto;
        gap: 15px;

        .group-list {
            .data-row-field {
                padding-left: 5px;
                display: grid;
                grid-template-columns: auto auto auto auto auto auto;
                background-color: var(--primary);
            }

            button {
                border: none;
                color: white;
                font-weight: bolder;
                background-color: transparent;
                height: 100%;

                &.active {
                    color: black;
                }
            }
        }

        .display-type {
            width: 100%;
            grid-template-columns: auto min-content;
            :only-child {
                grid-column-start: 1;
                grid-column-end: 2;
                gap: 0
            }
            select {
                border-radius: 5px;
                background-color: white;
                border: none;
            }
            p {
                cursor: pointer;
                margin: 0;
                color: var(--primary);
                padding-left: 15px;
            }
        }
    }

    .filters {
        filter: drop-shadow(0px 0px 4px #00000020);
        display: grid;

        .filter-title {
            display: grid;
            grid-template-columns: min-content auto min-content;
            background-color: var(--primary);
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            padding: 5px 10px;
            color: white;
            gap: 15px;
            align-items: center;

            svg {
                height: 100%;
                display: flex;
                align-items: center;
            }
            .title {

            }
            .clear {
                white-space: nowrap;
                font-weight: bold;
                font-size: 12px;
                display: flex;
                align-items: center;
            }
        }

        .filter-content {
            background-color: white;

            padding: 15px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            .filter-row {
                display: grid;
                gap: 5px;
                .filter-row-title {
                    font-weight: bold;
                }
                .filter-row-data {
                    display: grid;

                    @for $i from 0 through 12 {
                        &.items-#{$i} {
                            grid-template-columns: repeat($i, 1fr);
                            button {
                                padding-top: 5px;
                                padding-bottom: 5px;
                                border-radius: 0;
                                background-color: white;
                                border: 1px solid var(--primary);
                                border-right: none;
                                &:last-of-type {
                                    border: 1px solid var(--primary);
                                }
                                &:first-child {
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                }
    
                                &:last-child {
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
    
                                &.active {
                                    background-color: var(--primary);
                                    color: white;
                                }
                            }
    
                            &.items-#{$i} {
                                grid-template-columns: repeat($i, 1fr);
                            }
                        }
                    }
                    
                }
            }
        }
    }

    .add-user {
        display: flex;
        flex-direction: row; 
        justify-content: center; 
        align-items: center;
        gap: 5px; 
        transition: all 0.25s;
        cursor: pointer;
    }

    .add-user:hover {
        background: var(--primary);
        color: var(--white);
    }

    @media print {
        .secondary-grid div:last-child {
            margin-top: 45px;
        }
    }
}

.no-radius {
    border-top-right-radius: 0 !important;
}

.VACANT_COT_ALERTS-table-or-graph {
    background-color: white;
    filter: drop-shadow(0px 0px 4px #00000020);
    border-radius: 5px;
    padding: 20px;
    // max-height: 600px;
    &.using-graph {
        min-height: 500px;
        max-height: 646px;

        @media print {
            max-height: 590px;
        }
    }
}


.bulk-buy-button {
    background-color: #445468;
    color: white;
    font-weight: 700;
}

.bold {
    font-weight: 700;
}

.cancel {
    background-color: var(--red);
    color: white;
    font-weight: 700;
}

#vaca-confirmation-page {
    .datepicker .react-datepicker__calendar-icon {
        padding: 0.5rem;
    }
    .data-row-title {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// #void-sales-alerts-page > div:nth-child(3) > div > div > div.primary-grid > div:nth-child(2) > div:nth-child(1) > div.react-datepicker-wrapper > div > svg