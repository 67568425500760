.HP {
    display: grid; 
    grid-template-columns: 3fr 1fr; 
    gap: 25px;
}

select {
    width: 100%;
    height: 100%;
    border: none;
    background-color: white;
    padding-left: 10px;
}

.spid-list {
    list-style: none;
    padding: 0;
    align-self: center;
}

@media print {
    .HP {
        grid-template-columns: auto;
    }
    .HP .recharts-wrapper {
        height: 700px !important;
    }
    .HP .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}