.footer-wrapper {
    display: flex;
    justify-content: space-between;
    @media print {
        display: none;
    }
    div {
        padding: 2em 0;

        &.version {
            opacity: 0.2;
        }
    }
}
