.uprn-search {
    .gm-style-iw-chr {
        height: 1px;
    }

    .gm-style-iw-d {
        overflow: unset !important;
        margin: 6px 30px 3px 14px !important;
        color: #333;
    }

    .gm-ui-hover-effect {
        width: 24px !important;
        height: 24px !important;
        background-color: transparent;
        border: none;
        color: #007bff;
        font-size: 16px;
        font-weight: bold;

        span {
            width: 12px !important;
            height: 12px !important;
            margin: 0px !important;
        }
    }

    .gm-style-iw.gm-style-iw-c {
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

    .pop-up-content {
        ul {
            list-style-type: none;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            cursor: text;
            max-width: 25em;

            > li {
                margin-bottom: 0.5em;

                p {
                    margin: 0px;
                    font-weight: 500;
                }
            }
        }

        .stacked-markers {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            user-select: none;

            .prev-button, .next-button {
                cursor: pointer;
            }
        }
    }
}