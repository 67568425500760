.LTV {
    display: grid; 
    gap: 25px;

    &.summary {
        display: grid; 
        grid-template-columns: 3fr 1fr; 
        gap: 25px;
    }

    .data-row {
        display: grid;
        // grid-template-columns: min-content auto;
        filter: drop-shadow(0px 0px 4px #00000020);

        &.with-buttons {
            grid-template-columns: min-content auto min-content;

            button {
                padding: 5px;
            }
        }
    }

    .primary-grid {
        min-width: 100%;
        max-width: 70vw;
        display: grid;
        gap: 15px;
        grid-template-columns: 3fr 1fr;

        .left-grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;

            .left-grid-bottom {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
            }

            .name-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
            }

            .search-button {
                border: none;
                background-color: var(--primary);
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }
        }

        .right-grid {
            .search-button {
                border: none;
                background-color: var(--primary);
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }

            .buy-button {
                border: none;
                background-color: #445468;
                border-radius: 5px;
                color: white;
                padding: 4px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
                filter: drop-shadow(0px 0px 4px #00000020);

                &:disabled {
                    background-color: #ececec;
                    color: rgb(84, 84, 84);
                    font-weight: normal;
                }
            }
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    .secondary-grid {
        display: grid;
        min-height: 300px;

        > * {
            height: 150px;
        }

        .table {
            width: 100%;
            thead {
                font-size: 10px;
                tr {
                    th {
                        border: 1px solid #e8e8e8;
                        padding: 2px 10px 2px 10px;
                        &:first-child {
                            border-left: none;
                            width: 130px;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
            tbody {
                font-size: 10px;
                tr {
                    height: 10px;
                    overflow: hidden;
                    td {
                        height: 10px;
                        line-height: 10px;
                        overflow: hidden;
                        &:first-child {
                            text-align: left;
                        }
                        &:not(:first-child) {
                            word-wrap: normal;
                            word-break: break-all;
                            line-height: 10px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        .map {
            height: 100%;
            background-color: grey;
            &.hidden {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }
    }
}
